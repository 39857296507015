@import '../../styles/_constants.module.scss';


.container {
  position: relative;
  height: 100%;
  width: 100%;
}

.imageLoaded {
  opacity: 1;
  visibility: visible;

  img {
    opacity: 1;
    visibility: visible;
  }
}

// .responsiveImage {
//   opacity: 1;
//   visibility: visible;
// }

.hasMobileSrc {
  display: block;

  @include desktop-and-tablet {
    display: none;
  }
}
