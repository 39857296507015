@import '../_constants.module.scss';
@import '../type/type.scss';
@import '../common';

.main {
  @include main;
  background: $grey-light;
}

.hero {
  position: relative;
  height: 100vh;
  min-height: rem-property(1500px);
  z-index: 0;
  @include desktop-and-tablet {
    height: 150vh;
  }
  // padding-bottom: rem-property(750px);
}

.heroFixed {
  position: fixed;
  top: $header-small-height;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: -1;
  background: $black;
  transform: translateZ(0);
}

.heroContentContainer {
  position: absolute;
  top: calc(100vh - 18em);
  left: 0;
  width: 100%;

  @include desktop-and-tablet {
    position: relative;
    z-index: 1;
    height: 100%;
    top: unset;
    left: unset;
    width: auto;
  }
}

.heroImage {
  position: relative;
  width: 100%;
  height: 100%;
}

.maxWidthContainer {
  @include maxWidthContainer;
  position: relative;
}

.relativeMaxWidthContainer {
  @include maxWidthContainer;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.heroContent {
  width: 100%;
  padding: 0 $phone-margin;
  z-index: 2;
  transform: translateY(-50%);
  // margin-bottom: 50vh;

  @include desktop-and-tablet {
    position: absolute;
    left: 0;
    top: 50vh;
    bottom: unset;
    padding: 0 $desktop-margin;
  }
}

.heroTitle {
  @include abc_medium_65_110;
  color: $white;
  margin: 0 0 rem-property(22px);

  @include desktop-and-tablet {
    width: rem-property(685px);
    margin: 0 0 rem-property(22px);
  }

  p {
    margin: 0;
  }
}

.heroDetail {
  @include abc_regular_24_130;
  margin: 0;

  @include desktop-and-tablet {
    width: rem-property(685px);
  }

  p {
    margin-bottom: 0;
  }
}

.heroLink {
  @include maxWidthContainer;
  padding-bottom: $phone-margin-vert;
  padding-left: $phone-margin;
  // position: absolute;
  // top: calc(90vh);

  @include desktop-and-tablet {
    position: sticky;
    top: 90%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: $desktop-margin;
    padding-right: $desktop-margin;
    padding-left: 0;
  }
}

.heroContent {
  color: $white;
}

.contentContainer {
  position: relative;
  z-index: 1;
  background: $grey-light;
}

.aboutContainer {
  @include maxWidthContainer;
  padding: $phone-margin $phone-margin 0;

  @include desktop-and-tablet {
    padding: $desktop-margin $desktop-margin 0;
  }
}

.aboutTitle {
  @include abc_bold_140_89;
  margin: rem-property(64px) 0 $phone-margin;

  @include desktop-and-tablet {
    width: rem-property(885px);
    margin: 0 0 $desktop-margin;
  }
}

.aboutDetail {
  @include abc_regular_24_130;
  margin-bottom: rem-property(34px);

  @include desktop-and-tablet {
    width: rem-property(689px);
    margin-bottom: rem-property(34px);
  }
}
.aboutLink {
  margin-bottom: $phone-margin-vert;

  @include desktop-and-tablet {
    margin-bottom: 0;
  }
}

.aboutImage {
  display: block;
  margin-left: auto;
  margin-bottom: calc(-1 * #{rem-property(15px)});
  position: relative;
  z-index: 0;

  @include desktop-and-tablet {
    width: percentage(12 / 16);
    margin-top: calc(-1 * #{rem-property(115px)});
    margin-bottom: calc(-1 * #{rem-property(195px)});
  }
}

.aboutFootNote {
  @include abc_regular_24_130;
  margin-bottom: $phone-margin-vert * 2;

  @include desktop-and-tablet {
    margin-bottom: rem-property(220px);
  }
}

.batteriesContainer {
  margin-bottom: $phone-margin-vert;
  position: relative;
  z-index: 0;

  @include desktop-and-tablet {
    margin-bottom: rem-property(71px);
  }
}

.batteriesTitle {
  @include abc_bold_140_89;
  padding: 0 $phone-margin;
  margin: 0 0 $phone-margin;

  @include desktop-and-tablet {
    padding: 0 $desktop-margin;
    margin: 0 0 $desktop-margin;
    max-width: rem-property(1412px);
  }
}

.batteriesDetail {
  @include abc_regular_24_130;
  margin: 0 $phone-margin rem-property(49px);
  padding: 0;

  @include desktop-and-tablet {
    margin: 0 $desktop-margin rem-property(49px);

    width: rem-property(689px);
  }
}

.batteriesLink {
  padding: 0 $phone-margin;
  margin-bottom: $phone-margin-vert;

  @include desktop-and-tablet {
    padding: 0 $desktop-margin;
    margin-bottom: rem-property(49px);
  }
}

.swiperTitle {
  @include abc_mono_20_120;
  margin-top: rem-property(24px);
  text-transform: capitalize;

  @include desktop-and-tablet {
    margin-top: rem-property(24px);
  }
}

.swiperSlide {
  max-height: 100vw;
  width: 100%;

  @include desktop-and-tablet {
    max-height: rem-property(500px);
    width: rem-property(500px);
  }
}

.aboutImageContainer {
  position: relative;
  width: 100%;
  height: 80vh;

  @include desktop-and-tablet {
    width: 100%;
    height: rem-property(1392px);
    max-height: 90vh;
  }
}

.aboutImageContent {
  position: absolute;
  bottom: $phone-margin-vert;
  right: $phone-margin;
  width: calc(100% - #{$phone-margin} * 2);
  color: $white;

  @include desktop-and-tablet {
    top: 50%;
    bottom: unset;
    transform: translateY(-50%);
    right: $desktop-margin;
    width: rem-property(562px);
  }
}

.aboutImageTitle {
  @include abc_medium_65_110;
  margin: 0 0 $h2-margin;

  @include desktop-and-tablet {
    margin: 0 0 $h2-margin;
  }
}

.aboutImageDetail {
  @include abc_regular_24_130;
  margin: 0 0 rem-property(36px);

  @include desktop-and-tablet {
    margin: 0 0 rem-property(36px);
  }
}

.sustainabilityImageContainer {
  position: relative;
  margin: $phone-margin $phone-margin $phone-margin-vert;
  width: calc(100% - #{$phone-margin} * 2);
  height: 80vh;
  max-height: 80vh;

  @include desktop-and-tablet {
    margin: $desktop-margin $desktop-margin $desktop-margin * 2;
    width: calc(100% - #{$desktop-margin} * 2);
    height: rem-property(1001px);
    max-height: 90vh;
  }
}

.sustainabilityImageContent {
  position: absolute;
  bottom: $phone-margin-vert;
  // left: rem-property(785px);
  right: $phone-margin;
  width: calc(100% - #{$phone-margin} * 2);
  color: $white;

  @include desktop-and-tablet {
    top: 50%;
    transform: translateY(-50%);
    bottom: unset;
    // left: rem-property(785px);
    right: $desktop-margin;
    width: rem-property(562px);
  }
}

.sustainabilityImageTitle {
  @include abc_medium_65_110;
  margin: 0 0 $h2-margin;

  @include desktop-and-tablet {
    margin: 0 0 $h2-margin;
  }
}

.sustainabilityImageDetail {
  @include abc_regular_24_130;
  margin: 0 0 rem-property(36px);

  @include desktop-and-tablet {
    margin: 0 0 rem-property(36px);
  }
}

.newsSection {
  @include maxWidthContainer;
  padding: 0 $phone-margin;
  margin-bottom: $phone-margin-vert * 2;

  @include desktop-and-tablet {
    padding: 0 $desktop-margin;
    margin-bottom: rem-property(220px);
  }
}

.newsTitle {
  @include abc_regular_65_110;
  margin: $phone-margin 0 rem-property(13px);

  @include desktop-and-tablet {
    margin: $desktop-margin 0 rem-property(13px);
  }
}
