@import '../../styles/_constants.module.scss';
@import '../../styles/fonts.scss';
@import '../../styles/common.scss';

.section {
  @include maxWidthContainer;
  padding: 0 $phone-margin rem-property(220px);
  margin: 0 auto;

  @include desktop-and-tablet {
    padding: 0 $desktop-margin rem-property(220px);
  }
}

.title {
  @include abc_regular_65_110;
  margin: 0 0 13px;

  @include desktop-and-tablet {
    margin: 0 0 rem-property(13px);

  }
}

.imageContainer {
  // aspect-ratio: 786 / 547;
  display: block;

  @include desktop-and-tablet {
    width: percentage(9 / 16);
  }
}

.image {
  width: 100%;

  @include desktop-and-tablet {
  }
}

.detail {
  @include abc_regular_24_130;

  @include desktop-and-tablet {
    width: percentage(8 / 16);
  }
}
