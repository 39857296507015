@import '../../styles/_constants.module.scss';
@import '../../styles/fonts.scss';

.button {
  background: none;
  border: rem-property(1px) solid $white;
  border-radius: rem-property(30px);
  outline: none;
  color: $white;
  display: flex;
  align-items: center;
  height: rem-property(50px);
  padding: 0;
  position: relative;
  cursor: pointer;

  @include hover {
    background: $white;
    color: $black;
  }

  &.dark {
    color: $black;
    border-color: $black;

    @include hover {
      background: $black;
      color: $white;
    }
  }
}

.arrow {
  width: rem-property(11px);
  height: rem-property(8.24px);
  display: inline-block;
  position: absolute;
  left: rem-property(14px);
}

.text {
  @include abc_mono_13_120;
  margin-left: rem-property(34px);
  margin-right: rem-property(19px);
  min-width: rem-property(107px);
  text-align: center;
  text-transform: capitalize;

  &.wide {
    @include abc_mono_15_120;
    min-width: rem-property(155px);
  }
}
