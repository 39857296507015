@import './_constants.module.scss';
@import './fonts.scss';

@mixin main {
  background: $grey-light;
  padding: $header-small-height 0 0;
  display: flow-root;
}

@mixin maxWidthContainer {
  max-width: $max-width;
  margin: 0 auto;
}

@mixin relativeMaxWidthContainer {
  @include maxWidthContainer;
  position: absolute;
  top: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

@mixin maxHeightContainer {
  max-height: 100vh;
  overflow: hidden;

  > * {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@mixin hero-standard {
  @include maxWidthContainer;
  margin-bottom: $phone-margin-vert;

  @include desktop-and-tablet {
    margin-bottom: rem-property(62px);
  }
  .heroContentContainer {
    padding: 0 $phone-margin;

    @include desktop-and-tablet {
      padding: 0 $desktop-margin;
    }
  }

  .heroTitle {
    @include abc_medium_65_110;
    padding: 40px 0 0;
    margin: 0 0 $h2-margin-phone;
    min-height: calc(2.2em + #{40px});
    display: flex;
    align-items: flex-end;

    @include desktop-and-tablet {
      padding: rem-property(111px) 0 0;
      margin: 0 0 $h2-margin;
      width: percentage(8 / 16);
    }
  }

  .heroTitleWide {
    @include abc_medium_65_110;
    padding: $phone-margin-vert 0 0;
    margin: 0 0 $h2-margin-phone;
    width: 100%;

    @include desktop-and-tablet {
      padding: rem-property(111px) 0 0;

      margin: 0 0 $h2-margin;
      width: percentage(9 / 16);
    }

    p {
      margin: 0 0 1em 0;
    }
  }

  .heroImage {
    position: relative;
    height: 70vh;
    width: calc(100% - #{$phone-margin} * 2);
    margin: 0 auto 40px;

    @include desktop-and-tablet {
      // height: rem-property(785px);
      height: auto;
      width: calc(100% - #{$desktop-margin} * 2);
      aspect-ratio: 1371 / 685;
      margin: 0 auto $desktop-margin;
    }
  }
}
