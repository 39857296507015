@import '../../styles/_constants.module.scss';
@import '../../styles/fonts.scss';

.item {
  margin-bottom: $phone-margin-vert;

  @include desktop-and-tablet {
    margin-bottom: 0;
  }

  @include hover {
    color: $hover-color;

    .readMore {
      opacity: 1;
    }

    .imageContainer {
      opacity: 0.92;
    }

    // .title,
    // .category {
    //   color: $teal;
    // }

    // .detail {
    //   color: $black;
    // }
  }
}

.imageContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 330 / 232;
}

.category {
  @include abc-mono-regular(12px, 12px);

  margin: 0.75em 3ch 1em 0;
  text-transform: uppercase;
  display: inline-block;

  @include desktop-and-tablet {
    @include abc_mono_13_120;

    display: block;
    margin: rem-property(14px) 0 rem-property(14px);
  }
}

.title {
  @include abc-regular(20px, 20px);
  margin: 0.75em 0;
  padding-right: percentage(1 / 6);

  @include desktop-and-tablet {
    @include abc_regular_22_120;
  }
}

.titleOnly {
  composes: title;
  @include abc_regular_16_130;
}

.detail {
  composes: titleOnly;
}

.pubDate {
  @include abc-mono-regular(12px, 12px);

  margin: 1em 0;
  text-transform: uppercase;
  display: inline-block;

  @include desktop-and-tablet {
    @include abc_mono_13_120;

    display: block;
    margin: rem-property(10px) 0 rem-property(14px);
  }
}

.readMore {
  @include abc_mono_13_120;
  margin-top: 4em;
  text-transform: uppercase;

  @include desktop-and-tablet {
    @include abc_mono_13_120;
    margin-top: 3em;
  }
}
